import groupBy from 'lodash.groupby'
import uniqBy from 'lodash.uniqby'

export const transformProducts = (items = []) => {
  if (items.length === 0) return {}

  let itemWithParent = items.filter(({ parent }) => !!parent)

  let itemsWithoutParent = []
  itemsWithoutParent = items.filter(({ parent }) => !parent)
  itemsWithoutParent = itemsWithoutParent.map(item => ({
    ...item,
    parent: {
      id: item.custitem_velprod_item_name,
      value: item.custitem_velprod_item_name,
    },
  }))

  const groupedByParent = groupBy(
    [...itemWithParent, ...itemsWithoutParent],
    'parent.id',
  )

  const aaaa = Object.entries(groupedByParent)
    .map(([parentId, data]) => {
      const {
        parent,
        displaynametranslated,
        displayname,
        custitem_velprod_item_image_url,
        custitem_vel_product_stagione,
        'CUSTITEM_VELPROD_SOLE.custrecord_velprod_sole_nameit': soleType,
        custitem_vel_product_type,
        custitem_velprod_model,
        'cseg_vel_brand.internalid': { id: brand },
        formulanumeric: productionStatus,
      } = data[0]

      const productModelId = custitem_velprod_model?.id || null

      // Getting unique SKUs to simplify searchBy SKU
      const SKUs = uniqBy(data, 'custitem_velprod_item_name').map(
        ({ custitem_velprod_item_name }) => custitem_velprod_item_name,
      )

      const children = uniqBy(data, 'custitem_velprod_item_name').map(
        ({
          internalid,
          custitem1: { id, value } = {},
          custitem_velprod_item_name,
        }) => ({
          size: value,
          SKU: custitem_velprod_item_name,
          internalId: internalid,
        }),
      )

      // Getting unique sizeIDs to simplify searchBy sizeID (not using it... yet)
      const sizeIDs = uniqBy(data, 'custitem1.id').map(
        ({ custitem1: { id } = {} } = {}) => id,
      )

      // Getting unique internalIDs to simplify searchBy internalID
      const internalIDs = uniqBy(data, 'internalid.id').map(
        ({ internalid: { id } = {} } = {}) => id,
      )

      //cleaning up items
      let items = data.map(
        ({
          parent,
          displaynametranslated,
          displayname,
          custitem_velprod_item_image_url,
          'CUSTITEM_VELPROD_SOLE.custrecord_velprod_sole_nameit': __,
          ...item
        }) => ({
          ...item,
        }),
      )

      items = groupBy(items, 'inventorylocation.id')

      for (let inventoryLocationId in items) {
        const { inventorylocation } = items[inventoryLocationId][0]

        items[inventoryLocationId] = {
          inventoryLocation: inventorylocation,
          sizes: items[inventoryLocationId].map(
            ({
              inventorylocation,
              'inventoryLocation.internalid': _,
              // 'CUSTITEM_VEL_PRODUCT_TYPE.name': __,
              custitem_velprod_model_nameit,
              custitem_velprod_item_name: SKU,
              internalid: { id: internalId },
              custitem1: { id, value } = {},
              locationquantityavailable,
              locationquantityonorder,
              formulanumeric,
            }) => ({
              id,
              value,
              SKU,
              internalId,
              quantityAvailable: Number(locationquantityavailable),
              quantityOnOrder: Number(locationquantityonorder),
            }),
          ),
        }
      }

      return {
        parentId,
        data: {
          parent,
          name: displaynametranslated || displayname,
          imageUrl: custitem_velprod_item_image_url,
          inventoryLocations: items,
          soleType,
          SKUs,
          children,
          sizeIDs,
          internalIDs,
          productModelId,
          productionStatus:
            productionStatus === '1' ? 'Discontinued' : 'Still produced',
          productType: custitem_vel_product_type,
          season: custitem_vel_product_stagione || {
            id: 999,
            value: 'Senza stagione',
          },
          brand,
        },
      }
    })
    .reduce((acc, el) => {
      acc[el.parentId] = el.data

      return acc
    }, {})

  return aaaa
}
